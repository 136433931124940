export interface Company {
  companyId: number
  code: string
  logoUrl: string
  logoWhiteUrl: string
  name: string
  allowPortIn: boolean
}

export const COMPANIES: Company[] = [
  {
    companyId: 6,
    code: 'universal',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/universal.png',
    name: 'UNIVERSAL',
    allowPortIn: true,
  },
  {
    companyId: 7,
    code: 'humano',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/humano.png',
    name: 'HUMANO SEGUROS',
    allowPortIn: true,
  },
  {
    companyId: 2,
    code: 'mapfre_seguros',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/mapfre_seguros.png',
    name: 'MAPFRE BHD SEGUROS',
    allowPortIn: true,
  },
  {
    companyId: 1,
    code: 'mapfre_ars',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/mapfre_ars.png',
    name: 'MAPFRE SALUD ARS',
    allowPortIn: true,
  },
  {
    companyId: 3,
    code: 'sura',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/sura.png',
    name: 'SURA',
    allowPortIn: true,
  },
  {
    companyId: 10,
    code: 'sam',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/sam.png',
    name: 'SAM LATINOAMERICA',
    allowPortIn: false,
  },
  {
    companyId: 13,
    code: 'lacolonial',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/colonial.png',
    name: 'LA COLONIAL',
    allowPortIn: true,
  },
  {
    companyId: 12,
    code: 'seguroademi',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/ademi.png',
    name: 'SEGUROS ADEMI',
    allowPortIn: true,
  },
  {
    companyId: -1, //!
    code: 'atrio',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/atrio.png',
    name: 'ATRIO SEGUROS',
    allowPortIn: true,
  },
  {
    companyId: 9,
    code: 'reservas',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/reservas.png',
    name: 'SEGUROS RESERVAS',
    allowPortIn: true,
  },
  {
    companyId: -1, //!
    code: 'dominicana',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/dominicana.png',
    name: 'DOMINICANA DE SEGUROS',
    allowPortIn: true,
  },
  {
    companyId: 15,
    code: 'crecer',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/crecer.png',
    name: 'SEGUROS CRECER',
    allowPortIn: true,
  },
  {
    companyId: 8,
    code: 'monumental',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/monumental.png',
    name: 'LA MONUMENTAL DE SEGUROS',
    allowPortIn: true,
  },
  {
    companyId: 16,
    code: 'unit',
    logoUrl: '',
    logoWhiteUrl: '/img/company-logos/unit.png',
    name: 'UNIT',
    allowPortIn: true,
  },
]
